import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from '../../services/axios';

function AdminPayrollTable() {
    const [payrollData, setPayrollData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Get startDate from Redux store
    const { startDate } = useSelector(state => state.dateRange);

    // Extract the month and year from startDate
    const currentDate = new Date(startDate);
    const currentMonth = currentDate.getMonth() + 1;  // JavaScript months are 0-indexed
    const currentYear = currentDate.getFullYear();

    // Utility function to get the number of days in a given month and year
    const getDaysInMonth = (year, month) => {
        return new Date(year, month, 0).getDate();  // Month is 1-indexed for this function
    };

    // Get number of days in the selected month
    const daysInMonth = getDaysInMonth(currentYear, currentMonth);

    useEffect(() => {
        // Construct the API URL using the month and year
        const apiUrl = `/api/payroll/${currentMonth}/${currentYear}/`;

        axios.get(apiUrl)
            .then(response => {
                setPayrollData(response); // Set payroll data from the response
                setLoading(false);
            })
            .catch(() => {
                setError("Error fetching data");
                setLoading(false);
            });
    }, [currentMonth, currentYear]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!payrollData) {
        return <div>No data available</div>;
    }

    // Function to calculate total salary
    const calculateSalary = (baseSalary, totalDaysPresent, totalDaysInMonth) => {
        if (totalDaysInMonth === 0 || !baseSalary) return 0;

        // Convert baseSalary to a number, remove commas and 'VNĐ'
        const numericBaseSalary = parseFloat(baseSalary.replace(/,/g, '').replace(' VNĐ', '')) || 0;
        
        // Calculate daily salary and multiply by total days present
        const dailySalary = numericBaseSalary / totalDaysInMonth;
        const totalSalary = dailySalary * totalDaysPresent;

        return Math.round(totalSalary); // Round result to nearest integer
    };

    // Function to calculate bonus
    const calculateBonus = (baseBonus, totalRevenue) => {
        if (!baseBonus || !totalRevenue) return 0;

        // Convert baseBonus to a percentage (e.g., "10%" to 0.1)
        const bonusPercentage = parseFloat(baseBonus.replace('%', '')) / 100 || 0;
        const bonusInVND = bonusPercentage * totalRevenue * 23500; // Convert USD to VND

        return Math.round(bonusInVND); // Round to nearest integer
    };

    // Function to calculate total salary
    const calculateTotalSalary = (salary, bonus) => {
        return salary + bonus; // Add salary and bonus
    };

    // Initialize totals
    let totalDaysWorked = 0;
    let totalRevenue = 0;
    let totalSalary = 0;
    let totalBonus = 0;
    let totalFinalSalary = 0;

// Filter out employees with base_salary = 0 or base_salary is null/undefined
const filteredPayrollData = payrollData.filter(employee => {
    const baseSalary = employee.base_salary ? employee.base_salary.replace(/,/g, '').replace(' VNĐ', '') : null;
    const numericBaseSalary = parseFloat(baseSalary) || 0;
    return numericBaseSalary > 0;
});
    return (
        <div className="w-full relative shadow-md overflow-auto">
            <table className="text-sm w-full text-center rtl:text-right text-gray-500 dark:text-gray-400 mt-5">
                <thead className="text-xs text-center text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="border">Employee</th>
                        {[...Array(daysInMonth).keys()].map(day => (
                            <th key={day} className="border px-4 py-2">{day + 1}</th>
                        ))}
                        <th scope="col" className="border">Tổng số công</th> {/* Total days worked */}
                        <th scope="col" className="border">Total Revenue (USD)</th> {/* Total Revenue */}
                        <th scope="col" className="border">Lương (VNĐ)</th> {/* Salary */}
                        <th scope="col" className="border">Bonus (VNĐ)</th> {/* Bonus */}
                        <th scope="col" className="border">Lương thực tế (VNĐ)</th> {/* Total Salary */}
                    </tr>
                </thead>
                <tbody>
                    {filteredPayrollData.map((employee, idx) => {
                        const salary = calculateSalary(
                            employee.base_salary, 
                            employee.total_days_present, 
                            employee.total_days_in_month
                        );

                        const bonus = calculateBonus(
                            employee.base_bonus,
                            employee.total_revenue
                        );

                        const totalEmployeeSalary = calculateTotalSalary(salary, bonus);

                        // Accumulate totals
                        totalDaysWorked += employee.total_days_present;
                        totalRevenue += employee.total_revenue;
                        totalSalary += salary;
                        totalBonus += bonus;
                        totalFinalSalary += totalEmployeeSalary;

                        return (
                            <tr key={idx} className='bg-white text-xs text-nowrap'>
                                <td className="border px-4 py-2">{employee.username}</td>
                                {employee.attendance_days.slice(0, daysInMonth).map((day, dayIdx) => (
                                    <td key={dayIdx} className="border px-4 py-2">{day}</td>
                                ))}
                                <td className="border px-4 py-2">{employee.total_days_present}</td> {/* Total days present */}
                                <td className="border px-4 py-2">$ {employee.total_revenue.toLocaleString('en-US')}</td> {/* Total Revenue in USD */}
                                <td className="border px-4 py-2">{salary.toLocaleString('vi-VN')}₫</td> {/* Salary */}
                                <td className="border px-4 py-2">{bonus.toLocaleString('vi-VN')}₫</td> {/* Bonus */}
                                <td className="border px-4 py-2  text-blue-600 font-semibold">{totalEmployeeSalary.toLocaleString('vi-VN')}₫</td> {/* Total Salary */}
                            </tr>
                        );
                    })}

                    {/* Total row */}
                    <tr className="bg-gray-200 font-bold text-black text-xs text-nowrap">
                        <td className="border px-4 py-2">Tổng cộng</td>
                        {[...Array(daysInMonth).keys()].map(day => (
                            <td key={day} className="border px-4 py-2"></td>
                        ))}
                        <td className="border px-4 py-2  text-blue-600 font-semibold">{totalDaysWorked}</td> {/* Total days worked */}
                        <td className="border px-4 py-2  text-blue-600 font-semibold">$ {totalRevenue.toLocaleString('en-US')}</td> {/* Total Revenue */}
                        <td className="border px-4 py-2  text-blue-600 font-semibold">{totalSalary.toLocaleString('vi-VN')}₫</td> {/* Total Salary */}
                        <td className="border px-4 py-2  text-blue-600 font-semibold">{totalBonus.toLocaleString('vi-VN')}₫</td> {/* Total Bonus */}
                        <td className="border px-4 py-2 text-blue-600 font-semibold">{totalFinalSalary.toLocaleString('vi-VN')}₫</td> {/* Total Final Salary */}
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default AdminPayrollTable;
